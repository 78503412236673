import axios from "axios";
import camelize from "camelize";
import { getApiUri, getHeaders } from "./NetworkService";
import { isEmpty } from "../utils";

export const getTicketsRequest = async () => {
  const uri = getApiUri();

  let responseData = {
    "status": null,
    "data": null,
  };

  await axios({ method: "GET", url: `${uri}/api/v1/tickets/`, headers: getHeaders() })
    .then((response) => {
      if (response.status === 200) {
        responseData.status = "success";
        responseData.data = camelize(response.data);
      } else {
        responseData.status = "error";
        responseData.message = "Erreur lors de la récupération des billets";
      }
    }).catch((error) => {
      responseData.status = "error";
      responseData.message = "Erreur lors de la récupération des billets";
    });

  return responseData;
};

export const getTicketRequest = async ( id ) => {
  const uri = getApiUri();

  let responseData = {
    "status": null,
    "data": null,
  };

  await axios({ method: "GET", url: `${uri}/api/v1/tickets/${id}/`, headers: getHeaders() })
    .then((response) => {
      if (response.status === 200) {
        responseData.status = "success";
        responseData.data = camelize(response.data);
      } else {
        responseData.status = "error";
        responseData.message = "Erreur lors de la récupération du billet";
      }
    }).catch((error) => {
      responseData.status = "error";
      responseData.message = "Erreur lors de la récupération du billet";
    });

  return responseData;
};

export const createTicketRequest = async ( firstName, lastName, alias, gender, phone, leaderId, assistantId, yearStart, creatorId, countryName, synodeName, cityName, churchName, churchLocationName, borough, address, scheduledDay, scheduledHour ) => {
  const uri = getApiUri();

  const data = {
    first_name: firstName,
    last_name: lastName,
    alias: alias,
    gender: gender,
    phone: phone,
    leader_id: leaderId,
    assistant_id: assistantId,
    year_start: yearStart,
    creator_id: creatorId,
    country: countryName,
    synode: synodeName,
    city: cityName,
    church: churchName,
    church_location: churchLocationName,
    borough: borough,
    address: address,
    scheduled_day: scheduledDay,
    scheduled_hour: scheduledHour,
  };
  
  let responseData = {
    "status": null,
    "message": null,
    "data": null,
  };

  await axios({ method: "POST", url: `${uri}/api/v1/tickets/`, data: data, headers: getHeaders() })
    .then((response) => {
      if (response.status === 201) {
        responseData.status = "success";
        responseData.message = "Billet créé avec succès";
        responseData.data = camelize(response.data);
      } else {
        responseData.status = "error";
        responseData.message = "Création impossible";
      }
    }).catch((error) => {
      responseData.status = "error";
      responseData.message = "Création impossible";
    });

  return responseData;
};
