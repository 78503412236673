import React from "react";
import Header from "../components/Header";

const HomePage = () => {
  return (
    <div className="HomePage">
      <Header currentPage="home" />

      <div className="h-screen">
        <div className="relative h-screen bg-gray-900 flex flex-col space-y-5 justify-center items-center text-center text-white uppercase px-4">
          <img className="absolute w-full h-full object-cover opacity-25" src="./images/bishop_rex.jpg" alt="background" />
          <div className="z-30 container mx-auto space-y-2">
            <h1 className="text-4xl lg:text-6xl font-bold">Fonenan'ny voninahitra</h1>
            <h2 className="text-sm lg:text-base font-bold">Projet de construction d'église</h2>
            <h3 className="text-xs lg:text-sm">Disponible uniquement sur invitation</h3>
          </div>
        </div>
      </div>
    </div>
  )
};

export default HomePage;
