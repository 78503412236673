import axios from "axios";
import camelize from "camelize";
import { getApiUri } from "./NetworkService";
import { isEmpty } from "../utils";

export const getToken = () => {
  if (isEmpty(localStorage.getItem("token"))) {
    return null;
  }
  return "SHINE " + localStorage.getItem("token");
};

export const storeToken = (access, refresh) => {
  localStorage.setItem("token", access);
  localStorage.setItem("refresh", refresh);
};

export const refreshTokenRequest = async () => {
  const uri = getApiUri();

  const refresh = localStorage.getItem("refresh");

  const data = {
    refresh: refresh,
  };

  let responseData = {
    "status": null,
    "message": null,
    "data": null,
  };

  await axios({ method: "POST", url: `${uri}/api/v1/auth/jwt/refresh/`, data: data })
    .then((response) => {
      console.log(response);
      if (response.status === 200) {
        responseData.status = "success";
        responseData.message = "Token renouvellé";
        responseData.data = camelize(response.data);
      } else {
        responseData.status = "error";
        responseData.message = "Renouvellement de token impossible";
      }
    });

  return responseData;
};

export const loginUserRequest = async ( login, password ) => {
  const uri = getApiUri();

  const data = {
    phone: login,
    password: password,
  };

  let responseData = {
    "status": null,
    "message": null,
    "data": null,
  };

  await axios({ method: "POST", url: `${uri}/api/v1/auth/jwt/create/`, data: data })
    .then((response) => {
      if (response.status === 200) {
        responseData.status = "success";
        responseData.message = "Connecté avec succès";
        responseData.data = camelize(response.data);
      } else {
        responseData.status = "error";
        responseData.message = "Une erreur s'est produite";
      }
    }).catch((error) => {
      responseData.status = "warning";
      responseData.message = "Veuillez vérifier les données saisies";
    });

  return responseData;
};

export const logoutUserRequest = async () => {
  localStorage.removeItem("token");
  localStorage.removeItem("refresh");

  let responseData = {
    "status": "success",
    "message": "Déconnecté avec succès",
    "data": null,
  };

  return responseData;
};
