import React, { useState } from "react";
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { classNames, isEmpty } from '../../utils';
import { getToken } from "../../services/AuthenticationService";
import { NavLink } from "react-router-dom";

const Header = ({ currentPage, theme = "dark" }) => {
  const [backgroundColor, setBackgroundColor] = useState("bg-transparent");

  const navigation = [
    { name: 'Accueil', href: '/', current: currentPage === "home" ? true : false },
    { name: 'A propos', href: '/about', current: currentPage === "about" ? true : false },
    { name: 'Contact', href: '/contact', current: currentPage === "contact" ? true : false },
  ];

  const handleChangeBackground = () => {
    window.scrollY >= 50 ? setBackgroundColor("bg-dark shadow-xl") : setBackgroundColor("bg-transparent");
  };

  window.addEventListener('scroll', handleChangeBackground);

  return (
    <>
      <header className={classNames(theme === "dark" ? backgroundColor : "bg-sky-950", "Header w-full fixed z-50 py-4 transition ease-linear duration-200")}>
        <nav className="container mx-auto w-full px-4">
          <Disclosure>
            <div className="flex justify-between lg:grid grid-cols-12 w-full">
              <div className="col-span-3 flex items-center">
                <a className="opacity-85 hover:opacity-100 animation" href="/">
                  <h1 className="text-lg text-white font-semibold">Eglise Evangélique Shine</h1>
                </a>
              </div>
              <div className="flex lg:hidden">
                <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md p-2 text-white focus:outline-none focus:ring-0">
                  <span className="absolute -inset-0.5" />
                  <Bars3Icon aria-hidden="true" className="block h-6 w-6 group-data-[open]:hidden" />
                  <XMarkIcon aria-hidden="true" className="hidden h-6 w-6 group-data-[open]:block" />
                </DisclosureButton>
              </div>
              <div className="hidden lg:flex col-span-6 justify-center items-center">
                <div className="font-bold uppercase text-sm space-x-8">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      className={classNames(
                        item.current ? "text-gray-100" : "text-gray-400 hover:text-gray-200",
                        "animation",
                      )}
                      href={item.href}
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
              </div>
              <div className="hidden lg:flex col-span-3 justify-end items-center">
                <div className="text-gray-900 font-bold uppercase text-sm space-x-4 flex items-center">
                  {isEmpty(getToken()) ? (
                    <NavLink to="/login" className="btn bg-gray-200">Login</NavLink>
                  ) : (
                    <NavLink to="/backoffice/event/2/" className="btn bg-gray-200">Backoffice</NavLink>
                  )}
                </div>
              </div>
            </div>
            <DisclosurePanel className="sm:hidden">
              <div className="px-2 pb-3 pt-2 text-center space-y-2">
                <div className="space-y-1">
                  {navigation.map((item) => (
                    <DisclosureButton
                      key={item.name}
                      as="a"
                      href={item.href}
                      aria-current={item.current ? 'page' : undefined}
                      className={classNames(
                        item.current && "bg-gray-900",
                        "block rounded-md px-3 py-2 text-base font-medium text-gray-200",
                      )}
                    >
                      {item.name}
                    </DisclosureButton>
                  ))}
                </div>
                <div className="text-gray-100 font-bold uppercase text-sm flex flex-col space-y-2">
                  {isEmpty(getToken()) ? (
                    <NavLink to="/login" className="btn bg-gray-200 w-full">Login</NavLink>
                  ) : (
                    <NavLink to="/backoffice" className="btn bg-gray-200 w-full">Backoffice</NavLink>
                  )}
                </div>
              </div>
            </DisclosurePanel>
          </Disclosure>
        </nav>
      </header>
    </>
  )
};

export default Header;
