import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getTicketRequest } from "../../services/TicketService";
import { isEmpty } from "../../utils";

const EventTicketDetail = () => {
  const initialRender = useRef(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { ticketId } = useParams();
  
  const [ticket, setTicket] = useState();

  useEffect(() => {
    const fetchTicketData = async () => {
      await getTicket();
    };

    if (initialRender.current) {
      fetchTicketData();
    }
    return () => {
      initialRender.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTicket = async () => {
    const response = await getTicketRequest(ticketId);

    if (response.status === "success") {
      if (!isEmpty(response.data)) {
        setTicket(response.data);
        console.log(response.data);
      }
    } else {
      setTicket(null);
    }

    setLoading(false);
  };

  return (
    <div className="EventTicketDetail">
      <div className="container max-w-4xl px-4 py-8 h-screen flex flex-col justify-center">
        {loading ? (
          <div className="animate-pulse w-full">
            <div className="grid grid-cols-3 gap-8">
              <div className="w-full h-12 bg-gray-300 rounded"></div>
              <div className="w-full h-12 bg-gray-300 rounded"></div>
              <div className="w-full h-12 bg-gray-300 rounded"></div>
              <div className="w-full h-12 bg-gray-300 rounded"></div>
              <div className="w-full h-12 bg-gray-300 rounded"></div>
              <div className="w-full h-12 bg-gray-300 rounded"></div>
              <div className="w-full h-12 bg-gray-300 rounded"></div>
              <div className="w-full h-12 bg-gray-300 rounded"></div>
              <div className="w-full h-12 bg-gray-300 rounded"></div>
              <div className="w-full h-12 bg-gray-300 rounded"></div>
              <div className="w-full h-12 bg-gray-300 rounded"></div>
              <div className="w-full h-12 bg-gray-300 rounded"></div>
            </div>
          </div>
        ) : (
          isEmpty(ticket) ? (
            <div className="flex flex-col justify-center items-center text-center space-y-2 my-4 h-full">
              <div className="space-y-2">
                <h1 className="text-2xl font-semibold">Billet invalide !</h1>
              </div>
            </div>
          ) : (
            <div className="flex flex-col justify-between items-center text-center space-y-2 my-4 h-full">
              <div className="space-y-2">
                <h1 className="text-2xl font-semibold">Félicitations !</h1>
                <h3 className="text-lg">Vous êtes invité au CCI Ivato par</h3>
              </div>

              <div className="text-lg">
                {isEmpty(ticket.host) ? (
                  <>
                    <p>Invitant non renseigné</p>
                  </>
                ) : (
                  <>
                    <p>{ticket.host.titleDisplay} {ticket.host.firstName} {ticket.host.lastName}</p>
                    {!isEmpty(ticket.host.phone && (
                      <p>Contact : {ticket.host.phone}</p>
                    ))}
                  </>
                )}
              </div>

              <p className="font-semibold text-sm">NB : Veuillez vous munir de l'invitation lors de la réception</p>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default EventTicketDetail;
