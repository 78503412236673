import React, { useEffect, useRef, useState } from "react";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import DataTable, { createTheme } from "react-data-table-component";
import { BiSolidDownload } from "react-icons/bi";
import { isEmpty } from "../../utils";
import HeaderBackoffice from "../components/HeaderBackoffice";
import { downloadHostTicketsRequest, getHostsRequest } from "../../services/HostService";

const BackofficePage = () => {
  const initialRender = useRef(false);
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const { eventId } = useParams();
  
  const [hosts, setHosts] = useState([]);
  const [hostsFiltered, setHostsFiltered] = useState([]);

  useEffect(() => {
    const fetchHostData = async () => {
      await getHosts();
    };

    if (initialRender.current) {
      if (location.state) {
        if (!isEmpty(location.state.message)) {
          if (location.state.status === "success") {
            toast.success(location.state.message);
          } else if (location.state.status === "warning") {
            toast.warning(location.state.message);
          } else {
            toast.error(location.state.message);
          }
          window.history.replaceState({}, document.title);
        }
      }
      
      fetchHostData();
    }
    return () => {
      initialRender.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getHosts = async () => {
    const response = await getHostsRequest(eventId);

    if (response.status === "success") {
      if (!isEmpty(response.data)) {
        setHosts(response.data);
        setHostsFiltered(response.data);
      }
    } else {
      toast.warning("Aucune personne trouvée");
    }

    setLoading(false);
  };

  const handleSearch = (e) => {
    const newHosts = hosts.filter((host) => {
      return host.title.toLowerCase().includes(e.target.value.toLowerCase()) || host.firstName.toLowerCase().includes(e.target.value.toLowerCase()) || host.lastName.toLowerCase().includes(e.target.value.toLowerCase());
    });

    setHostsFiltered(newHosts);
  }

  // const handleHostTicketsDownload = async (e, id) => {
  //   e.preventDefault();

  //   const response = await downloadHostTicketsRequest(id);
  //   if (response.status === "success") {
  //     toast.success(response.message);
  //   } else {
  //     toast.error(response.message);
  //   }
  // };

  const handleHostTicketCopyLink = (e, id) => {
    e.preventDefault();

    const link = `${process.env.REACT_APP_API_URL}/download/tickets/event/2/host/${id}/invitation/`;

    navigator.clipboard.writeText(link);

    toast.success("Lien copié avec succès.");
  };

  // =============== DataTable Begin ===============
  const columns = [
    {
      name: "Propriétaire de billet",
      cell: row => (
        <div className="flex justify-start w-full">
          {`${row.title} ${row.firstName} ${row.lastName}`}
        </div>
      ),
      // selector: row => `${row.title} ${row.firstName} ${row.lastName}`,
      // sortable: true
    },
    {
      name: "Nombre de billet(s)",
      selector: row => row.tickets.length,
      // sortable: true
    },
    {
      name: "Plage de billets",
      selector: row => row.ticketRange,
      sortable: true
    },
    {
      name: "Lien de téléchargement",
      cell: row => (
        <div className="flex justify-end w-full">
          <button
            onClick={(e) => handleHostTicketCopyLink(e, row.id)}
            className="btn my-2 bg-gray-300 text-gray-900 w-full"
          >
            Copier le lien
          </button>
        </div>
      ),
    },
    {
      name: "Téléchargement",
      cell: row => (
        <div className="flex justify-center w-full">
          <NavLink
            className="border-2 rounded-full p-1"
            to={`${process.env.REACT_APP_API_URL}/download/tickets/event/2/host/${row.id}/invitation/`}
            target="_blank"
          >
            <BiSolidDownload size={20} />
          </NavLink>
          {/* <button
            onClick={(e) => handleHostTicketsDownload(e, row.id)}
            className="btn py-2.5 bg-tertiary-200 w-full"
          >
            Télécharger les billets
          </button> */}
        </div>
      ),
      button: "true"
    }
  ];
  
  createTheme('primary', {
    text: {
      primary: "rgb(209 213 219)",
      secondary: "",
    },
    background: {
      default: "",
    },
    divider: {
      default: "rgb(55 65 81)",
    },
    action: {
      button: 'rgba(0,0,0,.54)',
      hover: 'rgba(0,0,0,.08)',
      disabled: 'rgba(0,0,0,.12)',
    },
  }, 'dark');

  const customStyles = {
    pagination: {
      style: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
  }
  // =============== DataTable End ===============

  return (
    <div className="BackofficePage">
      <ToastContainer />
      <HeaderBackoffice />

      {loading ? (
        <div className="container pt-8 animate-pulse w-full">
          <div className="grid grid-cols-3 gap-8">
            <div className="w-full h-12 bg-gray-300 rounded"></div>
            <div className="w-full h-12 bg-gray-300 rounded"></div>
            <div className="w-full h-12 bg-gray-300 rounded"></div>
            <div className="w-full h-12 bg-gray-300 rounded"></div>
            <div className="w-full h-12 bg-gray-300 rounded"></div>
            <div className="w-full h-12 bg-gray-300 rounded"></div>
            <div className="w-full h-12 bg-gray-300 rounded"></div>
            <div className="w-full h-12 bg-gray-300 rounded"></div>
            <div className="w-full h-12 bg-gray-300 rounded"></div>
            <div className="w-full h-12 bg-gray-300 rounded"></div>
            <div className="w-full h-12 bg-gray-300 rounded"></div>
            <div className="w-full h-12 bg-gray-300 rounded"></div>
          </div>
        </div>
      ) : (
        <div className="container pt-6 space-y-8 mt-16">
          <div className="text-center">
            <a
              className="btn bg-green-700"
              href={`${process.env.REACT_APP_API_URL}/download/tickets/event/2/excel/`}
              target="_blank"
            >
              Exporter en fichier excel
            </a>
          </div>
          <div className="w-full text-center px-4">
            <input
              className="px-4 py-3 h-11 w-full lg:w-96 border text-sm text-gray-900 bg-gray-300 border-none rounded-md shadow-sm focus:outline-none focus:border-gray-900 focus:ring-1 focus:ring-gray-900"
              type="text"
              placeholder="Recherche"
              onChange={(e) => handleSearch(e)}
            />
          </div>
          <DataTable
            theme="primary"
            columns={columns}
            data={hostsFiltered}
            fixedHeader
            pagination
            customStyles={customStyles}
            defaultSortFieldId={3}
          />
        </div>
      )}
    </div>
  )
};

export default BackofficePage;
