import React, { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { isEmpty } from "../../utils";
import HeaderBackoffice from "../components/HeaderBackoffice";
import { assignHostTicket, getHostsRequest } from "../../services/HostService";

const AssignmentPage = () => {
  const initialRender = useRef(false);
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const { eventId } = useParams();
  
  const [hosts, setHosts] = useState([]);

  const [host, setHost] = useState(null);
  const [ticketBegin, setTicketBegin] = useState('');
  const [ticketEnd, setTicketEnd] = useState("");

  useEffect(() => {
    const fetchHostData = async () => {
      await getHosts();
    };

    if (initialRender.current) {
      if (location.state) {
        if (!isEmpty(location.state.message)) {
          if (location.state.status === "success") {
            toast.success(location.state.message);
          } else if (location.state.status === "warning") {
            toast.warning(location.state.message);
          } else {
            toast.error(location.state.message);
          }
          window.history.replaceState({}, document.title);
        }
      }
      
      fetchHostData();
    }
    return () => {
      initialRender.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getHosts = async () => {
    const response = await getHostsRequest(eventId);

    if (response.status === "success") {
      if (!isEmpty(response.data)) {
        setHosts(response.data);
      }
    } else {
      toast.warning("Aucune personne trouvée");
    }

    setLoading(false);
  };

  const handleChangeHost = (e) => {
    const hostId = e.target.value;
    const newHost = hosts.find((h) => h.id === hostId);
    setHost(newHost);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const response = await assignHostTicket(host.id, ticketBegin, ticketEnd, eventId);
    if (response.status === "success") {
      toast.success(response.message);
      await getHosts();
    } else if (response.status === "warning") {
      toast.warning(response.message);
    } else {
      toast.error(response.message);
    }

    setHost(null);
    setLoading(false);
  };

  return (
    <div className="AssignmentPage">
      <ToastContainer />
      <HeaderBackoffice currentPage="assignment" />

      {loading ? (
        <div className="container pt-8 animate-pulse w-full">
          <div className="grid grid-cols-3 gap-8">
            <div className="w-full h-12 bg-gray-300 rounded"></div>
            <div className="w-full h-12 bg-gray-300 rounded"></div>
            <div className="w-full h-12 bg-gray-300 rounded"></div>
            <div className="w-full h-12 bg-gray-300 rounded"></div>
            <div className="w-full h-12 bg-gray-300 rounded"></div>
            <div className="w-full h-12 bg-gray-300 rounded"></div>
            <div className="w-full h-12 bg-gray-300 rounded"></div>
            <div className="w-full h-12 bg-gray-300 rounded"></div>
            <div className="w-full h-12 bg-gray-300 rounded"></div>
            <div className="w-full h-12 bg-gray-300 rounded"></div>
            <div className="w-full h-12 bg-gray-300 rounded"></div>
            <div className="w-full h-12 bg-gray-300 rounded"></div>
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center py-6">
          <div className="w-full max-w-xl px-4 pt-8">
            <div className="bg-gray-300 space-y-8 p-8 sm:p-16 rounded-3xl shadow-2xl">
              <div className="flex justify-center">
                <h1 className="font-bold text-gray-900 uppercase text-4xl text-center">Assignation</h1>
              </div>
              <form className="flex flex-col space-y-8" onSubmit={(e) => handleFormSubmit(e)}>
                <div className="flex flex-col space-y-6">
                  <div className="flex flex-col space-y-2">
                    <label className="font-semibold text-gray-900" htmlFor="host">Propriétaire</label>
                    <input id="host" type="text" name="host" list="hostList" placeholder="Propriétaire du billet" required onChange={(e) => handleChangeHost(e)} className="px-4 py-3 h-11 border text-sm text-gray-700 bg-gray-200 border-none rounded-md shadow-sm focus:outline-none focus:border-gray-200 focus:ring-1 focus:ring-gray-200" />
                    <datalist id="hostList">
                      {hosts.map((host) => (
                        <option key={host.id} value={host.id}>{`${host.title} ${host.firstName} ${host.lastName}`}</option>
                      ))}
                    </datalist>
                    {!isEmpty(host) && (
                      <div className="text-gray-700 text-xs">
                        <h4>Billets obtenus : <strong>{host.tickets.length}</strong></h4>
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col space-y-2">
                    <label className="font-semibold text-gray-900" htmlFor="ticketBegin">Début du billet</label>
                    <input id="ticketBegin" className="px-4 py-3 h-11 border text-sm text-gray-700 bg-gray-200 border-none rounded-md shadow-sm focus:outline-none focus:border-gray-200 focus:ring-1 focus:ring-gray-200" type="text" name="ticketBegin" placeholder="Numéro de début du billet" required value={ticketBegin} onChange={(e) => setTicketBegin(e.target.value)} />
                  </div>
                  <div className="flex flex-col space-y-2">
                    <label className="font-semibold text-gray-900" htmlFor="ticketEnd">Fin du billet</label>
                    <input id="ticketEnd" className="px-4 py-3 h-11 border text-sm text-gray-700 bg-gray-200 border-none rounded-md shadow-sm focus:outline-none focus:border-gray-200 focus:ring-1 focus:ring-gray-200" type="text" name="ticketEnd" placeholder="Numéro de fin du billet" required value={ticketEnd} onChange={(e) => setTicketEnd(e.target.value)} />
                  </div>
                </div>
                <button className="btn bg-sky-950 hover:opacity-90 font-semibold text-gray-200" type="submit">Assigner</button>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  )
};

export default AssignmentPage;
