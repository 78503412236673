import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { TailSpin } from "react-loader-spinner";
import Header from "../components/Header";
import { createHostRequest } from "../../services/HostService";
import Stepper from "../components/Stepper";

const RegistrationPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [currentStep, setCurrentStep] = useState(1);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [title, setTitle] = useState("");
  const [synod, setSynod] = useState("");
  const [church, setChurch] = useState("");
  const [churchLocation, setChurchLocation] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [picture, setPicture] = useState();

  const [inscription, setInscription] = useState("");
  const [payment, setPayment] = useState("");

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (currentStep < 3) {
      if (step === currentStep) setStep(step + 1);
      setCurrentStep(currentStep + 1);
    } else if (currentStep === 3) {
    
    } else {
      setLoading(true);
  
      const response = await createHostRequest(firstName, lastName, title, synod, church, churchLocation, email, phone, picture, 3);
  
      if (response.status === "success") {
        navigate("/");
      } else {
        setLoading(false);
        toast.error(response.message);
      }
    }
  };

  return (
    <div className="RegistrationPage">
      <ToastContainer />
      <Header theme="light" />

      <div className="container pt-24 pb-2 px-4">
        <Stepper step={step} currentStep={currentStep} />
      </div>

      {loading ? (
        <div className="h-screen flex justify-center items-center">
          <TailSpin
            visible={true}
            height={85}
            width={85}
            color="#082f49"
            ariaLabel="tail-spin-loading"
            radius={1}
            strokeWidth={4}
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      ) : (
        <>
          {currentStep === 1 && (
            <div className="flex justify-center items-center">
              <div className="w-full max-w-5xl p-4">
                <div className="card-sm bg-white space-y-4">
                  <div className="space-y-2">
                    <h1 className="text-center text-gray-900 text-4xl font-semibold">Sekolin'ny ministera</h1>
                    <p className="text-center text-gray-900">Informations personnelles</p>
                  </div>
                  <form className="flex flex-col space-y-8" onSubmit={(e) => handleFormSubmit(e)}>
                    <div className="grid lg:grid-cols-2 gap-7">
                      <div className="flex flex-col space-y-2 lg:col-span-2">
                        <label className="font-semibold text-gray-900" htmlFor="picture">Photo <span className="text-red-400">*</span></label>
                        <input id="picture" className="px-4 py-3 h-11 border text-sm text-gray-700 bg-gray-200 border-none rounded-md shadow-sm focus:outline-none focus:border-gray-200 focus:ring-1 focus:ring-sky-900" type="file" name="picture" required onChange={(e) => setPicture(e.target.files[0])} />
                      </div>
                      <div className="flex flex-col space-y-2">
                        <label className="font-semibold text-gray-900" htmlFor="lastName">Nom <span className="text-red-400">*</span></label>
                        <input id="lastName" className="px-4 py-3 h-11 border text-sm text-gray-700 bg-gray-200 border-none rounded-md shadow-sm focus:outline-none focus:border-gray-200 focus:ring-1 focus:ring-sky-900" type="text" name="lastName" required value={lastName} onChange={(e) => setLastName(e.target.value)} />
                      </div>
                      <div className="flex flex-col space-y-2">
                        <label className="font-semibold text-gray-900" htmlFor="firstName">Prénom(s) <span className="text-red-400">*</span></label>
                        <input id="firstName" className="px-4 py-3 h-11 border text-sm text-gray-700 bg-gray-200 border-none rounded-md shadow-sm focus:outline-none focus:border-gray-200 focus:ring-1 focus:ring-sky-900" type="text" name="firstName" required value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                      </div>
                      <div className="flex flex-col space-y-2">
                        <label className="font-semibold text-gray-900" htmlFor="title">Titre <span className="text-red-400">*</span></label>
                        <select id="title" className="px-4 py-3 h-11 border text-sm text-gray-700 bg-gray-200 border-none rounded-md shadow-sm focus:outline-none focus:border-gray-200 focus:ring-1 focus:ring-sky-900" name="title" required value={title} onChange={(e) => setTitle(e.target.value)}>
                          <option value="">Veuillez choisir un titre</option>
                          <option value="bishop">Bishop</option>
                          <option value="reverend">Révérend</option>
                          <option value="apostle">Apostoly</option>
                          <option value="prophet">Mpaminany</option>
                          <option value="evangelist">Evanjelista</option>
                          <option value="teacher">Mpampianatra</option>
                          <option value="pastor">Pastera</option>
                          <option value="ep">Elève Pastera</option>
                          <option value="aspirant">Aspirant</option>
                          <option value="worship_leader">Mpitarika Fiderana</option>
                          <option value="leader">Mpitarika (ZP, cellule, département, groupe)</option>
                          <option value="servant">Mpiandraikitra</option>
                          <option value="sheep">Mpino</option>
                        </select>
                      </div>
                      <div className="flex flex-col space-y-2">
                        <label className="font-semibold text-gray-900" htmlFor="synod">Faritany <span className="text-red-400">*</span></label>
                        <select id="synod" className="px-4 py-3 h-11 border text-sm text-gray-700 bg-gray-200 border-none rounded-md shadow-sm focus:outline-none focus:border-gray-200 focus:ring-1 focus:ring-sky-900" name="synod" required value={synod} onChange={(e) => setSynod(e.target.value)}>
                          <option value="analamanga">Analamanga</option>
                          <option value="atsinanana">Avaratra</option>
                          <option value="atsinanana">Atsimo</option>
                          <option value="analamanga">Andrefana</option>
                          <option value="atsinanana">Atsinanana</option>
                        </select>
                      </div>
                      <div className="flex flex-col space-y-2">
                        <label className="font-semibold text-gray-900" htmlFor="church">Fiangonana <span className="text-red-400">*</span></label>
                        <input id="church" className="px-4 py-3 h-11 border text-sm text-gray-700 bg-gray-200 border-none rounded-md shadow-sm focus:outline-none focus:border-gray-200 focus:ring-1 focus:ring-sky-900" type="text" name="church" required value={church} onChange={(e) => setChurch(e.target.value)} />
                      </div>
                      <div className="flex flex-col space-y-2">
                        <label className="font-semibold text-gray-900" htmlFor="churchLocation">Aiza <span className="text-red-400">*</span></label>
                        <input id="churchLocation" className="px-4 py-3 h-11 border text-sm text-gray-700 bg-gray-200 border-none rounded-md shadow-sm focus:outline-none focus:border-gray-200 focus:ring-1 focus:ring-sky-900" type="text" name="churchLocation" required value={churchLocation} onChange={(e) => setChurchLocation(e.target.value)} />
                      </div>
                      <div className="flex flex-col space-y-2">
                        <label className="font-semibold text-gray-900" htmlFor="email">Adresse e-mail <span className="text-red-400">*</span></label>
                        <input id="email" className="px-4 py-3 h-11 border text-sm text-gray-700 bg-gray-200 border-none rounded-md shadow-sm focus:outline-none focus:border-gray-200 focus:ring-1 focus:ring-sky-900" type="email" name="email" required value={email} onChange={(e) => setEmail(e.target.value)} />
                      </div>
                      <div className="flex flex-col space-y-2">
                        <label className="font-semibold text-gray-900" htmlFor="phone">Téléphone</label>
                        <input id="phone" className="px-4 py-3 h-11 border text-sm text-gray-700 bg-gray-200 border-none rounded-md shadow-sm focus:outline-none focus:border-gray-200 focus:ring-1 focus:ring-sky-900" type="tel" name="phone" value={phone} onChange={(e) => setPhone(e.target.value)} />
                      </div>
                    </div>
                    <button className="btn bg-sky-950 hover:opacity-90 font-semibold text-gray-200" type="submit">Suivant</button>
                  </form>
                </div>
              </div>
            </div>
          )}

          {currentStep === 2 && (
            <div className="flex justify-center items-center">
              <div className="w-full max-w-5xl p-4">
                <div className="card-sm bg-white space-y-4">
                  <div className="space-y-2">
                    <h1 className="text-center text-gray-900 text-4xl font-semibold">Sekolin'ny ministera</h1>
                    <p className="text-center text-gray-900">Conditions et règlement</p>
                  </div>
                  <form className="flex flex-col space-y-4 text-gray-500" onSubmit={(e) => handleFormSubmit(e)}>
                    <div>
                      <h3 className="text-lg font-semibold">FR</h3>
                      <ol className="list-decimal">
                        <li>
                          <p className="text-justify">Assurez-vous de bien nous communiquer vos vraies informations (Photo, nom, prénom, etc). Si ce n'est pas le cas, veuillez cliquer sur précédent.</p>
                        </li>
                        <li>
                          <p className="text-justify">Si vous choisissez l'inscription avec Kit, nous allons prendre votre nom et prénom enregistrés ici pour la délivrance du certificat à la fin de formation.</p>
                        </li>
                        <li>
                          <p className="text-justify">Veuillez regarder atttentivement votre numéro d'inscription pour le paiement afin de ne pas se tromper.</p>
                        </li>
                        <li>
                          <p className="text-justify">Après la confirmation de votre paiement, vous allez recevoir un reçu par mail automatiquement. Veuillez vous assurer de pouvoir ouvrir le compte e-mail que vous avez renseigné dans la section précédente. Si ce n'est pas le cas, nous vous invitons à le changer <span className="text-red-700 font-semibold cursor-pointer" onClick={() => setCurrentStep(1)}>ici</span>.</p>
                        </li>
                      </ol>
                    </div>

                    <div className="flex gap-2">
                      <input className="" type="checkbox" id="conditionCheckbox" required />
                      <div>
                        <label htmlFor="conditionCheckbox">
                          J'accepte les termes et conditions ci-dessus{(" ")}
                          <small>(Manaiky ireo voalaza rehetra eo ambony aho)</small>
                        </label>
                      </div>
                    </div>

                    <div className="grid gap-4 lg:grid-cols-2">
                      <button className="btn bg-gray-950 hover:opacity-90 font-semibold text-gray-200" type="button" onClick={() => setCurrentStep(1)}>Précédent</button>
                      <button className="btn bg-sky-950 hover:opacity-90 font-semibold text-gray-200" type="submit">Suivant</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}

          {currentStep === 3 && (
            <div className="flex justify-center items-center">
              <div className="w-full max-w-5xl p-4">
                <div className="card-sm bg-white space-y-4">
                  <div className="space-y-2">
                    <h1 className="text-center text-gray-900 text-4xl font-semibold">Sekolin'ny ministera</h1>
                    <p className="text-center text-gray-900">Informations sur l'inscription</p>
                  </div>
                  <form className="flex flex-col space-y-4 text-gray-500" onSubmit={(e) => handleFormSubmit(e)}>
                    <div className="flex flex-col space-y-2">
                      <label className="font-semibold text-gray-900" htmlFor="inscription">Type d'inscription <span className="text-red-400">*</span></label>
                      <select id="inscription" className="px-4 py-3 h-11 border text-sm text-gray-700 bg-gray-200 border-none rounded-md shadow-sm focus:outline-none focus:border-gray-200 focus:ring-1 focus:ring-sky-900" name="inscription" required value={inscription} onChange={(e) => setInscription(e.target.value)}>
                        <option value="">Veuillez choisir un type d'inscription</option>
                        <option value="simple">Inscription : 50 000 Ar</option>
                        <option value="kit">Inscription VIP (+ certificat) : 60 000 Ar</option>
                        <option value="kit">Inscription VVIP (+ boky + certificat + place spéciale) : 100 000 Ar</option>
                      </select>
                    </div>

                    <div className="flex flex-col space-y-2">
                      <label className="font-semibold text-gray-900" htmlFor="payment">Mode de paiement <span className="text-red-400">*</span></label>
                      <select id="payment" className="px-4 py-3 h-11 border text-sm text-gray-700 bg-gray-200 border-none rounded-md shadow-sm focus:outline-none focus:border-gray-200 focus:ring-1 focus:ring-sky-900" name="payment" required value={payment} onChange={(e) => setPayment(e.target.value)}>
                        <option value="">Veuillez choisir un mode de paiement</option>
                        <option value="mvola">MVola</option>
                        <option value="airtel_money">Airtel Money</option>
                      </select>
                    </div>

                    <div className="grid gap-4 lg:grid-cols-2">
                      <button className="btn bg-gray-950 hover:opacity-90 font-semibold text-gray-200" type="button" onClick={() => setCurrentStep(2)}>Précédent</button>
                      <button className="btn bg-sky-950 hover:opacity-90 font-semibold text-gray-200" type="submit">Suivant</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}

          {currentStep === 4 && (
            <div className="flex justify-center items-center">
              <div className="w-full max-w-5xl p-4">
                <div className="card-sm bg-white space-y-4">
                  <div className="space-y-2">
                    <h1 className="text-center text-gray-900 text-4xl font-semibold">Sekolin'ny ministera</h1>
                    <p className="text-center text-gray-900">Paiement</p>
                  </div>
                  <form className="flex flex-col space-y-4 text-gray-500" onSubmit={(e) => handleFormSubmit(e)}>
                    <div className="flex gap-2">
                      <input className="" type="checkbox" id="conditionCheckbox" required />
                      <div>
                        <label htmlFor="conditionCheckbox">
                          J'accepte les termes et conditions ci-dessus{(" ")}
                          <small>(Manaiky ireo voalaza rehetra eo ambony aho)</small>
                        </label>
                      </div>
                    </div>

                    <div className="grid gap-4 lg:grid-cols-2">
                      <button className="btn bg-gray-950 hover:opacity-90 font-semibold text-gray-200" type="button" onClick={() => setCurrentStep(1)}>Précédent</button>
                      <button className="btn bg-sky-950 hover:opacity-90 font-semibold text-gray-200" type="submit">Suivant</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default RegistrationPage;
