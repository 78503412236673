import React from "react";
import { classNames } from "../../utils";

const Stepper = ({ step, currentStep }) => {
  const steps = [
    { name: "Informations personnelles", step: 1 },
    { name: "Conditions", step: 2 },
    { name: "Type d'inscription", step: 3 },
    { name: "Paiement", step: 4 },
  ];

  return (
    <div className="flex justify-between items-center gap-3">
      {steps.map((item, index) => (
        <>
          <div key={item.name} className="flex flex-col justify-center items-center">
            <span
              className={classNames(
                item.step <= step ? "cursor-pointer" : "cursor-not-allowed",
                item.step === currentStep && "bg-sky-950 text-white",
                "flex justify-center items-center border border-sky-950 w-10 h-10 rounded-full",
              )}
            >
              {item.step}
            </span>
            {/* <h6>Informations personnelles</h6> */}
          </div>
          {index < steps.length - 1 && (
            <hr key={item.name} className="w-full border-t-2 border-gray-300" />
          )}
        </>
      ))}
    </div>
  );
};

export default Stepper;
