import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Error404Page from "./pages/errors/Error404Page";
import HomePage from "./pages/commons/HomePage";
import LoginPage from "./pages/authentications/LoginPage";
import BackofficePage from "./pages/backoffices/BackofficePage";
import AssignmentPage from "./pages/backoffices/AssignmentPage";
import RegistrationPage from "./pages/events/RegistrationPage";
import EventTicketDetail from "./pages/events/EventTicketDetail";

const App = () => {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login/" element={<LoginPage />} />
          <Route path="/backoffice/event/:eventId/" element={<BackofficePage />} />
          <Route path="/backoffice/event/:eventId/assignment/" element={<AssignmentPage />} />
          <Route path="/event/:eventId/ticket/:ticketId/" element={<EventTicketDetail />} />
          <Route path="*" element={<Error404Page />} />
        </Routes>
      </BrowserRouter>
    );
  };
  
  export default App;
