import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { TailSpin } from 'react-loader-spinner';
import { isEmpty } from "../../utils";
import { loginUserRequest, storeToken } from "../../services/AuthenticationService";

const LoginPage = () => {
  const initialRender = useRef(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (initialRender.current) {
      if (location.state) {
        if (!isEmpty(location.state.message)) {
          if (location.state.status === "success") {
            toast.success(location.state.message);
          } else if (location.state.status === "warning") {
            toast.warning(location.state.message);
          } else {
            toast.error(location.state.message);
          }
          window.history.replaceState({}, document.title);
        }
      }
    }
    return () => {
      initialRender.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const response = await loginUserRequest(login, password);

    if (response.status === "success") {
      storeToken(response.data.access, response.data.refresh);
      navigate("/backoffice/event/2/");
    } else {
      setLoading(false);
      toast.error(response.message);
    }
  }

  return (
    <div className="LoginPage">
      <ToastContainer />

      {loading ? (
        <div className="h-screen flex justify-center items-center">
          <TailSpin
            visible={true}
            height={85}
            width={85}
            color="#d1d5db"
            ariaLabel="tail-spin-loading"
            radius={1}
            strokeWidth={4}
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      ) : (
        <div className="h-screen flex justify-center items-center py-6">
          <div className="w-full max-w-xl px-4">
            <div className="card bg-gray-100 space-y-8">
              <div className="space-y-2">
                <h1 className="text-center text-gray-900 text-4xl font-semibold">Connexion</h1>
                <p className="text-center text-gray-900">Veuillez vous connecter</p>
              </div>
              <form className="flex flex-col space-y-8" onSubmit={(e) => handleFormSubmit(e)}>
                <div className="flex flex-col space-y-6">
                  <div className="flex flex-col space-y-2">
                    <label className="font-semibold text-gray-900" htmlFor="login">E-mail ou téléphone</label>
                    <input id="login" className="px-4 py-3 h-11 border text-sm text-gray-700 bg-gray-200 border-none rounded-md shadow-sm focus:outline-none focus:border-gray-200 focus:ring-1 focus:ring-gray-200" type="text" name="email" placeholder="Adresse e-mail ou numéro de téléphone" required value={login} onChange={(e) => setLogin(e.target.value)} />
                  </div>
                  <div className="flex flex-col space-y-2">
                    <label className="font-semibold text-gray-900" htmlFor="password">Mot de passe</label>
                    <input id="password" className="px-4 py-3 h-11 border text-sm text-gray-700 bg-gray-200 border-none rounded-md shadow-sm focus:outline-none focus:border-gray-200 focus:ring-1 focus:ring-gray-200" type="password" name="password" placeholder="Password" required value={password} onChange={(e) => setPassword(e.target.value)} />
                  </div>
                </div>
                <button className="btn bg-sky-950 hover:opacity-90 font-semibold text-gray-200" type="submit">Se connecter</button>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LoginPage;
