import axios from "axios";
import camelize from "camelize";
import { getApiUri, getHeaders } from "./NetworkService";
import { isEmpty } from "../utils";

export const getHostsRequest = async ( eventId ) => {
  const uri = getApiUri();

  let responseData = {
    "status": null,
    "data": null,
  };

  let url;
  isEmpty(eventId) ? url = `${uri}/api/v1/hosts/` : url = `${uri}/api/v1/hosts/?event=${eventId}`;

  await axios({ method: "GET", url: url, headers: getHeaders() })
    .then((response) => {
      if (response.status === 200) {
        responseData.status = "success";
        responseData.data = camelize(response.data);
      } else {
        responseData.status = "error";
        responseData.message = "Erreur lors de la récupération des propriétaires de billet";
      }
    }).catch((error) => {
      responseData.status = "error";
      responseData.message = "Erreur lors de la récupération des propriétaires de billet";
    });

  return responseData;
};

export const getHostRequest = async ( id ) => {
  const uri = getApiUri();

  let responseData = {
    "status": null,
    "data": null,
  };

  await axios({ method: "GET", url: `${uri}/api/v1/hosts/${id}/`, headers: getHeaders() })
    .then((response) => {
      if (response.status === 200) {
        responseData.status = "success";
        responseData.data = camelize(response.data);
      } else {
        responseData.status = "error";
        responseData.message = "Erreur lors de la récupération du propriétaire de billet";
      }
    }).catch((error) => {
      responseData.status = "error";
      responseData.message = "Erreur lors de la récupération du propriétaire de billet";
    });

  return responseData;
};

export const createHostRequest = async ( firstName, lastName, title, synod, church, churchLocation, email, phone, picture, eventId ) => {
  const uri = getApiUri();

  const formData = new FormData();
  formData.append("first_name", firstName);
  formData.append("last_name", lastName);
  formData.append("title", title);
  formData.append("synod", synod);
  formData.append("church", church);
  formData.append("church_location", churchLocation);
  formData.append("email", email);
  formData.append("phone", phone);
  formData.append("picture", picture);
  formData.append("event", eventId);
  
  let responseData = {
    "status": null,
    "message": null,
    "data": null,
  };

  await axios({ method: "POST", url: `${uri}/api/v1/hosts/`, data: formData, headers: getHeaders() })
    .then((response) => {
      if (response.status === 201) {
        responseData.status = "success";
        responseData.message = "Propriétaire créé avec succès";
        responseData.data = camelize(response.data);
      } else {
        responseData.status = "error";
        responseData.message = "Création impossible";
      }
    }).catch((error) => {
      responseData.status = "error";
      responseData.message = "Création impossible";
    });

  return responseData;
};

export const downloadHostTicketsRequest = async ( id ) => {
  const uri = getApiUri();

  let responseData = {
    "status": null,
  };

  await axios({ method: "GET", url: `${uri}/api/v1/hosts/${id}/tickets/`, headers: getHeaders() })
    .then((response) => {
      if (response.status === 200) {
        responseData.status = "success";
        responseData.message = "Lancement du téléchargement des billets";
      } else {
        responseData.status = "error";
        responseData.message = isEmpty(response.data.detail) ? "Erreur lors du téléchargement des billets" : response.data.detail;
      }
    }).catch((error) => {
      responseData.status = "error";
      responseData.message = isEmpty(error.response.data.detail) ? "Erreur lors du téléchargement des billets" : error.response.data.detail;
    });

  return responseData;
};

export const assignHostTicket = async ( id, ticketBegin, ticketEnd, eventId ) => {
  const uri = getApiUri();

  const data = {
    ticket_begin: ticketBegin,
    ticket_end: ticketEnd,
  };

  let responseData = {
    "status": null,
    "message": null,
    "data": null,
  };

  await axios({ method: "POST", url: `${uri}/api/v1/hosts/${id}/assign/?event=${eventId}`, data: data, headers: getHeaders() })
    .then((response) => {
      if (response.status === 200) {
        responseData.status = "success";
        responseData.message = "Assignation terminée avec succès";
        responseData.data = camelize(response.data);
      } else {
        responseData.status = "error";
        responseData.message = isEmpty(response.data.detail) ? "Une erreur s'est produite" : response.data.detail;
      }
    }).catch((error) => {
      if (error.response.status === 304) {
        responseData.status = "warning";
        responseData.message = isEmpty(error.response.data.detail) ? "Ce ou ces billets ont déjà été assignés à la personne" : error.response.data.detail;
      } else {
        responseData.status = "error";
        responseData.message = isEmpty(error.response.data.detail) ? "Une erreur s'est produite" : error.response.data.detail;
      }
    });

  return responseData;
};
