import React from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { logoutUserRequest } from "../../services/AuthenticationService";

const HeaderBackoffice = () => {
  const navigate = useNavigate();
  const { eventId } = useParams();

  const handleLogout = async (e) => {
    e.preventDefault();

    const response = await logoutUserRequest();

    navigate("/login/", {
      state: {
        status: response.status,
        message: response.message,
      }
    });
  };

  return (
    <header className="Header mt-8 container">
      <nav className="h-20 bg-gray-300 rounded-xl flex justify-between items-center px-12">
        <NavLink to={`/backoffice/event/${eventId}/`}>
          <h1 className="text-gray-900 text-xl font-semibold">Ticketing</h1>
        </NavLink>
        <div className="flex justify-center">
          <NavLink to={`/backoffice/event/${eventId}/assignment/`} className="text-gray-700 hover:text-gray-900">
            Assignation
          </NavLink>
        </div>
        <button className="btn bg-red-700" onClick={(e) => handleLogout(e)}>Déconnexion</button>
      </nav>
    </header>
  )
};

export default HeaderBackoffice;
